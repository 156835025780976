import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { settings } from "../settings";

const ACCESS_TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";

export const logout = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  window.location.href = `${settings.LOGOUT_ENDPOINT}?redirect_uri=${window.location.href}`;
};

const fetchToken = async (code: string) => {
  const { CLIENT_ID, TOKEN_ENDPOINT, REDIRECT_URI } = settings;

  const params = new URLSearchParams({
    grant_type: "authorization_code",
    client_id: CLIENT_ID,
    code,
    redirect_uri: REDIRECT_URI,
  });

  const { data } = await Axios.post(TOKEN_ENDPOINT, params, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

  const { access_token, refresh_token } = data;
  localStorage.setItem(ACCESS_TOKEN, access_token);
  localStorage.setItem(REFRESH_TOKEN, refresh_token);

  return data;
};

const refreshToken = async () => {
  const { CLIENT_ID, TOKEN_ENDPOINT } = settings;
  const readRefreshToken = localStorage.getItem(REFRESH_TOKEN);
  if (!readRefreshToken) throw new Error("Kein Refresh-Token verfügbar.");

  const params = new URLSearchParams({
    grant_type: REFRESH_TOKEN,
    client_id: CLIENT_ID,
    refresh_token: readRefreshToken,
  });

  const { data } = await Axios.post(TOKEN_ENDPOINT, params, {
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
  });

  const { access_token, refresh_token: newRefreshToken } = data;
  localStorage.setItem(ACCESS_TOKEN, access_token);
  if (newRefreshToken) localStorage.setItem(REFRESH_TOKEN, newRefreshToken);

  return access_token;
};

export const redirectToAuthorize = () => {
  const params = new URLSearchParams({
    client_id: settings.CLIENT_ID,
    redirect_uri: settings.REDIRECT_URI,
    response_type: settings.RESPONSE_TYPE,
    scope: settings.SCOPE,
  });
  localStorage.setItem("redirect_uri", window.location.href);
  window.location.href = `${settings.AUTHORIZE_ENDPOINT}?${params.toString()}`;
};

const authClient = Axios.create();

authClient.interceptors.request.use(
  (config) => {
    config.baseURL = settings.API_URL;
    const accessToken = localStorage.getItem(ACCESS_TOKEN);
    if (accessToken) {
      config.headers["Authorization"] = `${accessToken}`;
    }
    return config;
  },
  (error) => Promise.reject(error as Error),
);

authClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const newToken = await refreshToken();
        originalRequest.headers["Authorization"] = `${newToken}`;
        return authClient(originalRequest);
      } catch (refreshError) {
        console.error(
          "Token Refresh fehlgeschlagen. Weiterleitung zur Anmeldung...",
        );
        redirectToAuthorize();
        return Promise.reject(refreshError as Error);
      }
    }

    return Promise.reject(error as Error);
  },
);

export const createAxiosInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  return authClient({ ...config, ...options }).then((response) => ({
    ...response,
    data: response.data as T,
  }));
};

export type ErrorType<Error> = AxiosError<Error>;

export { authClient, fetchToken, refreshToken };
