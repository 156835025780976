import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  CloseButton,
  Container,
} from "@chakra-ui/react";
import React from "react";
import { useNotifications } from "../providers/NotificationProvider";

export default function Notifications() {
  const { notifications, dismissNotification } = useNotifications();
  if (notifications.length === 0) return null;

  return (
    <Container maxW="full" padding={5}>
      {notifications.map((notification) => (
        <Alert key={notification.id} status={notification.type}>
          <AlertIcon />
          <Box>
            <AlertDescription>
              <>{notification.message}</>
            </AlertDescription>
          </Box>
          <CloseButton
            alignSelf="flex-start"
            position="relative"
            right={-1}
            top={-1}
            onClick={() => {
              dismissNotification(notification.id);
            }}
          />
        </Alert>
      ))}
    </Container>
  );
}
