/**
 * Generated by orval v7.1.1 🍺
 * Do not edit manually.
 * rx2b ext-memoire
 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  DefinedInitialDataOptions,
  DefinedUseQueryResult,
  MutationFunction,
  QueryFunction,
  QueryKey,
  UndefinedInitialDataOptions,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import { createAxiosInstance } from "./mutator";
import type { ErrorType } from "./mutator";
export interface CreateSectionVersionResponse {}

export interface CreateSectionVersionRequest {
  /** */
  content?: string;
}

export interface UpdateSectionResponse {
  /** */
  externalId?: string;
}

export interface UpdateSectionRequest {
  /** */
  title?: string;
}

export interface CreateSectionResponse {
  /** */
  externalId?: string;
}

export interface CreateSectionRequest {
  /** */
  title?: string;
}

export interface SectionVersionListItem {
  /** */
  content?: string;
  /** */
  externalId?: string;
}

export interface SectionListItem {
  /** */
  externalId?: string;
  latestVersion?: SectionVersionListItem;
  /** */
  title?: string;
}

export interface SectionListResponse {
  /** */
  sections?: SectionListItem[];
}

export interface ErrorResponse {
  /** */
  description?: string;
  /** */
  key?: string;
}

export interface HealthResponse {
  status?: string;
}

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

export const getHealth = (
  options?: SecondParameter<typeof createAxiosInstance>,
  signal?: AbortSignal,
) => {
  return createAxiosInstance<HealthResponse>(
    { url: `/health`, method: "GET", signal },
    options,
  );
};

export const getGetHealthQueryKey = () => {
  return [`/health`] as const;
};

export const getGetHealthQueryOptions = <
  TData = Awaited<ReturnType<typeof getHealth>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData>
  >;
  request?: SecondParameter<typeof createAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetHealthQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getHealth>>> = ({
    signal,
  }) => getHealth(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getHealth>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetHealthQueryResult = NonNullable<
  Awaited<ReturnType<typeof getHealth>>
>;
export type GetHealthQueryError = ErrorType<unknown>;

export function useGetHealth<
  TData = Awaited<ReturnType<typeof getHealth>>,
  TError = ErrorType<unknown>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getHealth>>,
        TError,
        TData
      >,
      "initialData"
    >;
  request?: SecondParameter<typeof createAxiosInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetHealth<
  TData = Awaited<ReturnType<typeof getHealth>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getHealth>>,
        TError,
        TData
      >,
      "initialData"
    >;
  request?: SecondParameter<typeof createAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetHealth<
  TData = Awaited<ReturnType<typeof getHealth>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData>
  >;
  request?: SecondParameter<typeof createAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetHealth<
  TData = Awaited<ReturnType<typeof getHealth>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getHealth>>, TError, TData>
  >;
  request?: SecondParameter<typeof createAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetHealthQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const getSectionList = (
  options?: SecondParameter<typeof createAxiosInstance>,
  signal?: AbortSignal,
) => {
  return createAxiosInstance<SectionListResponse>(
    { url: `/sections`, method: "GET", signal },
    options,
  );
};

export const getGetSectionListQueryKey = () => {
  return [`/sections`] as const;
};

export const getGetSectionListQueryOptions = <
  TData = Awaited<ReturnType<typeof getSectionList>>,
  TError = ErrorType<ErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSectionList>>, TError, TData>
  >;
  request?: SecondParameter<typeof createAxiosInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetSectionListQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getSectionList>>> = ({
    signal,
  }) => getSectionList(requestOptions, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getSectionList>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetSectionListQueryResult = NonNullable<
  Awaited<ReturnType<typeof getSectionList>>
>;
export type GetSectionListQueryError = ErrorType<ErrorResponse>;

export function useGetSectionList<
  TData = Awaited<ReturnType<typeof getSectionList>>,
  TError = ErrorType<ErrorResponse>,
>(options: {
  query: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSectionList>>, TError, TData>
  > &
    Pick<
      DefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSectionList>>,
        TError,
        TData
      >,
      "initialData"
    >;
  request?: SecondParameter<typeof createAxiosInstance>;
}): DefinedUseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetSectionList<
  TData = Awaited<ReturnType<typeof getSectionList>>,
  TError = ErrorType<ErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSectionList>>, TError, TData>
  > &
    Pick<
      UndefinedInitialDataOptions<
        Awaited<ReturnType<typeof getSectionList>>,
        TError,
        TData
      >,
      "initialData"
    >;
  request?: SecondParameter<typeof createAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };
export function useGetSectionList<
  TData = Awaited<ReturnType<typeof getSectionList>>,
  TError = ErrorType<ErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSectionList>>, TError, TData>
  >;
  request?: SecondParameter<typeof createAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey };

export function useGetSectionList<
  TData = Awaited<ReturnType<typeof getSectionList>>,
  TError = ErrorType<ErrorResponse>,
>(options?: {
  query?: Partial<
    UseQueryOptions<Awaited<ReturnType<typeof getSectionList>>, TError, TData>
  >;
  request?: SecondParameter<typeof createAxiosInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } {
  const queryOptions = getGetSectionListQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
}

export const postCreateSection = (
  createSectionRequest: CreateSectionRequest,
  options?: SecondParameter<typeof createAxiosInstance>,
) => {
  return createAxiosInstance<CreateSectionResponse>(
    {
      url: `/sections`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createSectionRequest,
    },
    options,
  );
};

export const getPostCreateSectionMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCreateSection>>,
    TError,
    { data: CreateSectionRequest },
    TContext
  >;
  request?: SecondParameter<typeof createAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCreateSection>>,
  TError,
  { data: CreateSectionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCreateSection>>,
    { data: CreateSectionRequest }
  > = (props) => {
    const { data } = props ?? {};

    return postCreateSection(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCreateSectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCreateSection>>
>;
export type PostCreateSectionMutationBody = CreateSectionRequest;
export type PostCreateSectionMutationError = ErrorType<ErrorResponse>;

export const usePostCreateSection = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCreateSection>>,
    TError,
    { data: CreateSectionRequest },
    TContext
  >;
  request?: SecondParameter<typeof createAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCreateSection>>,
  TError,
  { data: CreateSectionRequest },
  TContext
> => {
  const mutationOptions = getPostCreateSectionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const putUpdateSection = (
  externalId: string,
  updateSectionRequest: UpdateSectionRequest,
  options?: SecondParameter<typeof createAxiosInstance>,
) => {
  return createAxiosInstance<UpdateSectionResponse>(
    {
      url: `/sections/${externalId}`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateSectionRequest,
    },
    options,
  );
};

export const getPutUpdateSectionMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUpdateSection>>,
    TError,
    { externalId: string; data: UpdateSectionRequest },
    TContext
  >;
  request?: SecondParameter<typeof createAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putUpdateSection>>,
  TError,
  { externalId: string; data: UpdateSectionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putUpdateSection>>,
    { externalId: string; data: UpdateSectionRequest }
  > = (props) => {
    const { externalId, data } = props ?? {};

    return putUpdateSection(externalId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutUpdateSectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof putUpdateSection>>
>;
export type PutUpdateSectionMutationBody = UpdateSectionRequest;
export type PutUpdateSectionMutationError = ErrorType<ErrorResponse>;

export const usePutUpdateSection = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putUpdateSection>>,
    TError,
    { externalId: string; data: UpdateSectionRequest },
    TContext
  >;
  request?: SecondParameter<typeof createAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putUpdateSection>>,
  TError,
  { externalId: string; data: UpdateSectionRequest },
  TContext
> => {
  const mutationOptions = getPutUpdateSectionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const deleteUnsetSection = (
  externalId: string,
  options?: SecondParameter<typeof createAxiosInstance>,
) => {
  return createAxiosInstance<void>(
    { url: `/sections/${externalId}`, method: "DELETE" },
    options,
  );
};

export const getDeleteUnsetSectionMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUnsetSection>>,
    TError,
    { externalId: string },
    TContext
  >;
  request?: SecondParameter<typeof createAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof deleteUnsetSection>>,
  TError,
  { externalId: string },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof deleteUnsetSection>>,
    { externalId: string }
  > = (props) => {
    const { externalId } = props ?? {};

    return deleteUnsetSection(externalId, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteUnsetSectionMutationResult = NonNullable<
  Awaited<ReturnType<typeof deleteUnsetSection>>
>;

export type DeleteUnsetSectionMutationError = ErrorType<ErrorResponse>;

export const useDeleteUnsetSection = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof deleteUnsetSection>>,
    TError,
    { externalId: string },
    TContext
  >;
  request?: SecondParameter<typeof createAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof deleteUnsetSection>>,
  TError,
  { externalId: string },
  TContext
> => {
  const mutationOptions = getDeleteUnsetSectionMutationOptions(options);

  return useMutation(mutationOptions);
};

export const postCreateSectionVersion = (
  externalId: string,
  createSectionVersionRequest: CreateSectionVersionRequest,
  options?: SecondParameter<typeof createAxiosInstance>,
) => {
  return createAxiosInstance<CreateSectionVersionResponse>(
    {
      url: `/sections/${externalId}/version`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: createSectionVersionRequest,
    },
    options,
  );
};

export const getPostCreateSectionVersionMutationOptions = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCreateSectionVersion>>,
    TError,
    { externalId: string; data: CreateSectionVersionRequest },
    TContext
  >;
  request?: SecondParameter<typeof createAxiosInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof postCreateSectionVersion>>,
  TError,
  { externalId: string; data: CreateSectionVersionRequest },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof postCreateSectionVersion>>,
    { externalId: string; data: CreateSectionVersionRequest }
  > = (props) => {
    const { externalId, data } = props ?? {};

    return postCreateSectionVersion(externalId, data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PostCreateSectionVersionMutationResult = NonNullable<
  Awaited<ReturnType<typeof postCreateSectionVersion>>
>;
export type PostCreateSectionVersionMutationBody = CreateSectionVersionRequest;
export type PostCreateSectionVersionMutationError = ErrorType<ErrorResponse>;

export const usePostCreateSectionVersion = <
  TError = ErrorType<ErrorResponse>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof postCreateSectionVersion>>,
    TError,
    { externalId: string; data: CreateSectionVersionRequest },
    TContext
  >;
  request?: SecondParameter<typeof createAxiosInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof postCreateSectionVersion>>,
  TError,
  { externalId: string; data: CreateSectionVersionRequest },
  TContext
> => {
  const mutationOptions = getPostCreateSectionVersionMutationOptions(options);

  return useMutation(mutationOptions);
};
