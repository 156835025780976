export const settings = {
  PUBLIC_URL: process.env.PUBLIC_URL ?? "",
  API_URL: process.env.REACT_APP_API_URL ?? "http://localhost:3005/api",
  TOKEN_ENDPOINT:
    process.env.REACT_APP_TOKEN_ENDPOINT ?? "http://localhost:3001/api/token",
  AUTHORIZE_ENDPOINT:
    process.env.REACT_APP_AUTHORIZE_ENDPOINT ??
    "http://localhost:3001/api/authorize",
  LOGOUT_ENDPOINT:
    process.env.REACT_APP_LOGOUT_ENDPOINT ?? "http://localhost:3001/api/logout",
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID ?? "ext-memoire-client-id",
  REDIRECT_URI: process.env.REACT_APP_REDIRECT_URI ?? "http://localhost:3006/",
  RESPONSE_TYPE: process.env.REACT_APP_RESPONSE_TYPE ?? "code",
  SCOPE: process.env.REACT_APP_SCOPE ?? "openid profile email",
};

export const fetchSettings = async () => {
  const response = await fetch("/config.json");
  if (!response.ok) {
    throw new Error("Failed to fetch config");
  }
  Object.assign(settings, await response.json());
};
