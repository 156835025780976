import { Center, ChakraProvider, Spinner } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { fetchToken, redirectToAuthorize, refreshToken } from "./api/mutator";
import { Navbar } from "./components/Navbar";
import Notifications from "./components/Notifications";
import { NotificationsProvider } from "./providers/NotificationProvider";
import { Editor } from "./screens/Editor";
import { settings } from "./settings";

const queryClient = new QueryClient();

const handleAuthCode = async (code: string) => {
  try {
    const tokens = await fetchToken(code);
    console.log("Token erfolgreich erhalten:", tokens);
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete("code");

    const redirectUri = localStorage.getItem("redirect_uri");
    if (redirectUri) {
      window.location.replace(redirectUri);
    } else {
      window.history.replaceState(null, document.title, settings.PUBLIC_URL);
    }
  } catch (error) {
    console.error("Fehler beim Abrufen des Tokens:", error);
    redirectToAuthorize();
  }
};

export default function App() {
  const authInProgressRef = useRef(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      if (authInProgressRef.current) return;
      authInProgressRef.current = true;

      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get("code");

      try {
        if (code) {
          await handleAuthCode(code);
        } else {
          await refreshToken();
          console.log("Token erfolgreich erneuert");
        }
        setIsLoading(false);
        authInProgressRef.current = false;
      } catch (error) {
        console.error("Kein gültiges Token. Weiterleitung zur Anmeldung...");
        redirectToAuthorize();
      }
    };

    initAuth();
  });

  return (
    <QueryClientProvider client={queryClient}>
      <ChakraProvider>
        {isLoading ? (
          <Center height="100vh">
            <Spinner size="xl" variant="" />
          </Center>
        ) : (
          <NotificationsProvider autoDismiss={2000}>
            <BrowserRouter basename={process.env.PUBLIC_URL}>
              <Notifications />
              <Navbar />
              <Routes>
                <Route key="sections" path={`/`} element={<Editor />} />
                <Route
                  key="section"
                  path={`/:sectionId`}
                  element={<Editor />}
                />
              </Routes>
            </BrowserRouter>
          </NotificationsProvider>
        )}
      </ChakraProvider>
    </QueryClientProvider>
  );
}
