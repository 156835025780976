import { CloseIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  IconButton,
  Input,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetSectionList, usePostCreateSection } from "../api/ext-memoire";
import { logout } from "../api/mutator";

export const Navbar: React.FC = () => {
  const { isOpen, onToggle } = useDisclosure();
  const navigate = useNavigate();
  const { data: sectionListData, refetch } = useGetSectionList();
  const [newSectionTitle, setNewSectionTitle] = useState("");

  const { mutateAsync: createSection } = usePostCreateSection({
    mutation: {
      onSuccess: () => {
        refetch();
      },
    },
  });

  const onCreateSection = useCallback(async () => {
    const data = await createSection({ data: { title: newSectionTitle } });
    if (data.status === 201) {
      navigate(`../${data.data.externalId}`);
      setNewSectionTitle("");
      onToggle();
    } else {
      console.error("error occured");
    }
  }, [createSection, navigate, setNewSectionTitle, newSectionTitle]);

  return (
    <>
      <Box
        as="nav"
        bg="white"
        height="40px"
        boxShadow="sm"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingX="20px"
        position="relative"
        zIndex="1000"
        marginBottom="25px"
      >
        {/* Linke Seite der Navbar */}
        <Box display="flex" alignItems="center">
          <Text
            fontSize="md"
            color="#250000"
            fontWeight="bold"
            cursor="pointer"
            mr={1}
          >
            rx2b
          </Text>
          <Text fontSize="lg" fontWeight="bold" cursor="pointer">
            extMemoire
          </Text>
          <Text
            ml={4}
            cursor="pointer"
            _hover={{ textDecoration: "underline", color: "tomato" }}
            onClick={() => {
              navigate("/");
            }}
          >
            Home
          </Text>
        </Box>

        {/* Runder Button für das Menü */}
        <Box
          position="relative"
          display="flex"
          justifyContent="flex-end"
          flex="1"
        >
          <IconButton
            aria-label="Menu"
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            borderRadius="full"
            bg="#250000"
            color="white"
            size="lg"
            width="60px"
            height="60px"
            position="absolute"
            bottom="-15px"
            right="0"
            transform="translateY(50%)"
            boxShadow="lg"
            onClick={onToggle}
            style={{
              boxShadow: "0px 0px 15px 4px #FFFFFF",
            }}
          />
        </Box>
      </Box>

      <motion.div
        initial={{ scaleY: 0 }}
        animate={{ scaleY: isOpen ? 1 : 0 }}
        style={{
          overflow: "hidden",
          position: "absolute",
          top: "40px",
          left: "0",
          right: "0",
          zIndex: 999,
          transformOrigin: "top",
        }}
      >
        <Box bg="white" boxShadow="lg" pt={10}>
          <Box
            p={3}
            textAlign="end"
            justifyItems="center"
            justifyContent="center"
          >
            <Text fontWeight="bold" display="inline">
              TODO
            </Text>
            <Button
              ml={2}
              variant="outline"
              onClick={() => {
                logout();
              }}
            >
              Abmelden
            </Button>
          </Box>
          <Box px={5}>
            <Input
              type="category"
              value={newSectionTitle}
              onChange={(event) => {
                setNewSectionTitle(event.target.value);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  onCreateSection();
                }
              }}
              placeholder="Neue Kategorie"
            />
          </Box>
          <SimpleGrid m={5} columns={[2, null, 4]} spacing={5}>
            {sectionListData?.data?.sections?.map((section) => (
              <Box
                key={section.externalId}
                bg="#250000"
                color="white"
                p={3}
                borderRadius="md"
                display="flex"
                justifyContent="center"
                alignItems="center"
                boxShadow="md"
                cursor="pointer"
                _hover={{ bg: "gray.300", boxShadow: "xl" }}
                onClick={() => {
                  navigate(`../${section.externalId}`);
                  onToggle();
                }}
              >
                {section.title}
              </Box>
            ))}
          </SimpleGrid>
          <Box h={1}></Box>
        </Box>
      </motion.div>
    </>
  );
};
